import { Image, Button, ActionSheet, Tag, NavBar, Popup, Toast, Icon, Loading } from 'vant';
import apis from '../utils/apis'
export default {
  name: 'skiCouponList',
  components: {
    'van-image': Image,
    'van-button': Button,
    'van-action-sheet': ActionSheet,
    'van-tag': Tag,
    'van-nav-bar': NavBar,
    'van-popup': Popup,
    'van-icon': Icon,
    'van-loading': Loading,
  },
  data () {
    return {
      list: [],
      navArr: [
        { name: '未使用', state: 1 },
        { name: '已使用', state: 2 },
        { name: '已过期', state: 3 },],
      acNav: sessionStorage.getItem('cpTab') ? Number(sessionStorage.getItem('cpTab')) : 0,
      params: {
        userPhone: '',
        token: this.$global.token,
        status: 1,
      },
      refreshing: false, //是否刷新
      showTips: false,//显示提示
      isLoading: true,//显示提示
      currut: {}, // 当前使用数据
    }
  },
  methods: {
    back() {
      sessionStorage.clear();
      localStorage.clear();
      if(this.$global.modelState){
        window.android.exit();
      }else {
        this.$iosMethod(function(bridge) {
          bridge.callHandler('exit', null,function(data) {
          })
        })
      }
    },
    //查电话号码
    findUserInfo () {
      let me = this;
      me.$post({
        url: apis.findUserInfo,
        params: {
          token: me.$global.token,
          deviceId: ''
        }
      }).then(res => {
        this.params.userPhone = res.data.phoneNumber;
        this.listCoupon();
      });
    },
    /**打开详细说明 */
    openTips (row) {
      this.currut = row
      this.showTips = !this.showTips
    },
    /**去使用 */
    goUse (row) {
      console.log(row)
      if (row.status == 1) {
        if (row.commodityList.length > 0) {
          let obj = row.commodityList[0]
          if (obj.commodityKind == 1) {
            this.goUrl(obj)
          } else if (obj.commodityKind == 3) {
            this.goUrlHotel(obj)
          } else if (obj.commodityKind == 4) {
            this.goUrlls(obj)
          } else {
            Toast('未获取到商品类型，请联系管理员配置商品类型');
          }
        }else{
          Toast('商品信息未配置，请联系管理员~')
        }
      } else {
        Toast('消费劵已使用或已过期~')
      }
    },
    couponListGoods(row){
      this.$router.push({
        path:'/couponListGoods',
        query:{
          couponId:row.couponId,
          couponName:row.couponName,
        }
      })
    },
    /**自营 票务跳转 */
    goUrl (row) {
      this.$router.push({
        path: '/submitOrderQw',
        query: {
          commodityId: row.commodityId,
          sellerId: row.sellerId,
          isSelfSupport: row.sellerIsSelfSupport, //  是否自营
          isRealName: row.sellerIsRealName, //  是否实名
        }
      })
    },
    /**酒店跳转 */
    goUrlHotel (row) {
      //
      this.$router.push({
        path: '/hotelInner',
        query: {
          commodityId: row.commodityId,
          sellerId: row.sellerId
        }
      })
    },
    /**零售跳转 */
    goUrlls (row) {
      this.$router.push({
        path: '/goodsInfo',
        query: {
          commodityId: row.commodityId
        }
      })
    },
    //切换tab
    changeState (row, index) {
      let _t = this;
      _t.acNav = index;
      sessionStorage.setItem('cpTab', index);
      this.listCoupon();
    },
    listCoupon () {
      this.isLoading = false;
      let _t = this;
      _t.params.status = _t.navArr[_t.acNav].state;
      this.$post({
        url: apis.listCoupon,
        params: { ..._t.params }
      }).then(res => {
        if (_t.refreshing) {
          _t.refreshing = false;
        }
        _t.list = res.data

      });
    },
  },
  created () {
    setTimeout(() => {
      if (this.$global.token) {
        this.params.token = this.$global.token;
        this.findUserInfo()
      }
    },2000)
  },
  mounted () {
  },
}
