<template>
  <div class="commain">
    <!-- 顶部 -->
    <van-nav-bar title="我的消费劵" @click-left="$router.go(-1)">
      <template #left>
        <van-icon color="#fff" :size="22" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="ol_out" :class="list.length ? 'bg_white':''">
      <nav class="ol_tab ali_c j_s d_f">
        <div class="ol_tab_item" v-for="(item,idx) in navArr" :key="idx" :class="idx === acNav ? 'ac' : ''" @click="changeState(item,idx)">
          <span class="p_r d_i">{{item.name}}</span>
        </div>
      </nav>
      <section class="ol_card_out">
        <div v-if="list.length">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <div v-for="(item,index) in list" :key="index" class="mb_12">
              <div class="olc_item_out" :key="index" :class="acNav == 0?'':'gray'" @click="couponListGoods(item)">
                <div class="left">
                  <p class="price">{{item.couponAmount?item.couponAmount/100:0}} <em>元</em></p>
                  <p class="name">{{item.couponName}}</p>
                  <p class="time">有效期：{{item.useBeginDate}} 至 {{item.useEndDate}}</p>
                  <p class="tips">
                  <span @click.stop="item.showPop = !item.showPop">详细说明
                    <template v-if="!item.showPop">
                      ▼
                    </template>
                    <template v-else>
                      ▲
                    </template>
                  </span>
                  </p>
                </div>
                <div class="rigth">
                  {{navArr[acNav].name}}
                </div>
              </div>
              <div class="pop" v-if="item.showPop">
                <div>
                  <h4>消费劵详细说明</h4>
                  <div class="content" v-html="item.useRules">
                </div>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </div>
        <!--无订单数据-->
        <div v-if="!list.length && !isLoading">
          <div class="d_f ali_c j_c">
            <div>
              <div class="no_order_out t_a">
                <img src="../imgs/no_order.png" alt="">
                <div class="title">你还没有消费劵哦！</div>
                <div class="sub">“我没有离开家，只是把家带回了远方~”</div>
                <div class="home_btn cur_p t_a d_i" @click="$router.push('/')">回家去看看吧~</div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>

    <van-loading v-if="isLoading" size="32" class="t_a"/>
  </div>
</template>
<script src='./skiCouponList.js'></script>
<style lang="scss" scoped src='./skiCouponList.scss'></style>
