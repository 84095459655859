let apis = {
  pagePreferentialCommodity:'/commodity/pagePreferentialCommodity',//获取首页分页查询优惠商品
  findCommodity:'/commodity/findCommodity',//通过商品Id获取商品详情
  create:'/goodsOrder/create',// 零售创建订单
  findOrder:'/goodsOrder/find/',// 零售查看订单信息
  pay:"/stlsOrder/createPayInfo", // 发起支付
  queryOrderStatus: 'stlsOrder/queryOrderStatus', //查询支付状态
  enumsList:'/enums/list',//根据key获取所有枚举
  refundApplyRetail: '/goodsOrder/refundApply',//套餐申请退款
  pageMyOrderRetail: '/goodsOrder/pageMyOrder',//零售订单列表
  cancelOrder: '/stlsOrder/cancel',//订单取消
  deleteOrder: '/stlsOrder/deleteOrder',//删除订单
  revokeRefundApply: '/stlsOrder/compose/revokeRefundApply',//撤销退款申请
  queryOrderList: '/stlsOrder/queryOrderList', //查询订单信息
  findByCode: '/content/findByCode', //查询订单信息
  findByColumnCode: '/content/findByColumnCode', //查询订单信息
  listCoupon: '/bizCoupon/listCoupon', //查询消费劵信息
  findUserInfo: '/userInfo/findUserInfo', //查询人信息
  ticketDetail: '/stlsOrder/findOrderDetail',
  postUserPoint: '/bizCoupon/postUserPoint', //评价
  queryCouponListByCommditysId: '/bizCoupon/queryCouponListByCommditysId',//根据商品ID查询可用优惠券列表
};

export default apis
